import { useState } from 'react';
import { FaThumbsUp, FaThumbsDown } from 'react-icons/fa';
import styled from 'styled-components';
import { useSession, addCommentPoint } from 'apis';
import { useAlert } from 'react-alert';
import { useMutation } from 'react-query';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100px;
  margin: 5px 0;
`;

const Result = styled.div`
  color: #4caf50;
  &[data-positive='false'] {
    color: #f44336;
  }
`;

const ThumbWrapper = styled.button`
  border: none;
  font-size: 20px;
  background-color: transparent;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: background-color 0.3s;
  &:hover {
    background-color: var(--opacity-medium);
  }
  &[data-selected='true'] {
    color: var(--primary);
  }
`;

export const LikesArea = ({ points, commentpoints, commentId }) => {
  const [userVote, setUserVote] = useState(commentpoints[0]?.point || 0);
  const [currentPoints, setCurrentPoints] = useState(points);
  const alert = useAlert();
  const { data: session } = useSession();

  const { mutate } = useMutation(addCommentPoint, {
    onSuccess: (data, { point }) => {
      setCurrentPoints(data.points);
      setUserVote(point);
    },
    onError: () => {
      alert.error('Nie uda się ocenić tego komentarza');
    },
  });

  const handleChangeVote = (point) => async () => {
    if (!session) return alert.error('Musisz być zalogowany');
    if (point === userVote) return;
    mutate({ commentId, point });
  };

  return (
    <Wrapper>
      <Result data-positive={currentPoints >= 0}>{currentPoints}</Result>
      <ThumbWrapper data-selected={userVote === 1} onClick={handleChangeVote(userVote === 1 ? 0 : 1)}>
        <FaThumbsUp />
      </ThumbWrapper>
      <ThumbWrapper data-selected={userVote === -1} onClick={handleChangeVote(userVote === -1 ? 0 : -1)}>
        <FaThumbsDown />
      </ThumbWrapper>
    </Wrapper>
  );
};
