import { useRef, useEffect } from 'react';
import { FiSend } from 'react-icons/fi';
import { addComment, editComment, useComments, useSession } from 'apis';
import { useAlert } from 'react-alert';
import { AvatarArea } from 'components/AvatarArea';
import { useMutation } from 'react-query';
import { useLoginPopup } from 'hooks/useLoginPopup';
import { TextMessageAreaWrapper, TextArea, SendButton, NotLoginInfo } from './index.style';

export const Provider = ({
  contentId,
  contentType,
  commentValue = '',
  isEditing,
  setIsEditing,
  commentId,
  setOpenNested,
}) => {
  const { refetch } = useComments({ contentId, contentType });
  const alert = useAlert();
  const { data: session } = useSession();
  const textarea = useRef();

  const editMutation = useMutation(editComment, {
    onSuccess: async () => {
      await refetch();
      setIsEditing(false);
    },
    onError: () => {
      alert.error('Nie udało się zmienić komentarza');
    },
  });

  const addMutation = useMutation(addComment, {
    onSuccess: async () => {
      await refetch();
      textarea.current.innerText = '';
      if (setOpenNested) {
        setOpenNested(true);
      }
    },
    onError: () => {
      alert.error('Nie udało się dodać komentarza');
    },
  });

  const handleSendMessage = async () => {
    const value = textarea.current.outerText.trim();

    if (!value.length) return alert.error('Musisz coś napisać aby dodać komentarz');

    if (isEditing && setIsEditing) {
      editMutation.mutate({ commentId, value });
    } else {
      addMutation.mutate({ content_id: contentId, content_type: contentType, value });
    }
  };

  useEffect(() => {
    if(!commentValue) return;

    textarea.current.focus();
    textarea.current.innerText = commentValue;
  }, [commentValue]);

  return (
    <>
      <TextMessageAreaWrapper data-contentid={contentId} data-contenttype={contentType} data-commentid={commentId}>
        {!isEditing && <AvatarArea author={session.user} />}
        <TextArea ref={textarea} contentEditable role="textbox" data-placeholder="Dodaj komentarz ..." />
        <SendButton title="Send message" onClick={handleSendMessage}>
          <FiSend />
        </SendButton>
      </TextMessageAreaWrapper>
      <hr />
    </>
  );
};

export const TextCommentArea = (props) => {
  const { data: session } = useSession();
  const { setLoginPopup } = useLoginPopup();

  if (!session) {
    return <NotLoginInfo onClick={() => setLoginPopup(true)}>Zaloguj się aby móc dodać komentarz</NotLoginInfo>;
  }

  return <Provider {...props} />;
};
