import { useState } from 'react';
import { useAlert } from 'react-alert';
import { Card, CardHeader } from 'components/Card';
import { useActivePoll } from 'apis';
import { sendVoteInThePoll } from 'apis/others';
import { Loading } from 'components/Loading';
import { useRouter } from 'next/router';
import { Seo } from 'components/Seo';
import { Comments } from 'components/Comments';
import { COMMENT_CONTENT_TYPES } from 'utils/constants';
import { MdOpenInNew } from 'react-icons/md';
import Link from 'next/link';
import { useMutation } from 'react-query';
import {
  AnswerLabel,
  AnswersWrapper,
  Question,
  CardInnerWrapper,
  WidgetWrapper,
  SubmitButton,
  External,
  Center,
} from './PollsCard.styles';

const Form = ({ pollId }) => {
  const alert = useAlert();
  const { data, refetch } = useActivePoll({ pollId });

  const { id, votes_count, answers, votes } = data;
  const selectedAnswerId = votes[0]?.answer_id;

  const [currentAnswerId, setCurrentAnswerId] = useState(selectedAnswerId);

  const calculateResults = (count) => {
    const value = Math.round((count / votes_count) * 10000) / 100;
    return `${Number.isNaN(value) ? 0 : value}%`;
  };

  const { mutate } = useMutation(sendVoteInThePoll, {
    onSuccess: () => {
      alert.success('Dziękujemy za oddanie głosu w ankiecie!');
      refetch();
    },
    onError: () => {
      alert.error('Wystąpił błąd, spróbuj ponownie później');
    },
  });

  const onSubmit = () => {
    mutate({
      pollId: id,
      answerId: currentAnswerId,
    });
  };

  return (
    <div>
      <AnswersWrapper>
        {answers.map((answer) => {
          const isSelected = currentAnswerId === answer.id;
          const result = calculateResults(answer.votes_count);
          return (
            <AnswerLabel key={answer.id} data-isselected={isSelected} result={result} data-show={!!selectedAnswerId}>
              {answer.answer} {selectedAnswerId ? `(${result})` : ''}
              <input
                type="radio"
                name="poll"
                checked={isSelected}
                onChange={() => {
                  if (selectedAnswerId) return;
                  setCurrentAnswerId(answer.id);
                }}
              />
            </AnswerLabel>
          );
        })}
      </AnswersWrapper>
      {selectedAnswerId ? (
        <Center>Razem {votes_count} głosów</Center>
      ) : (
        <SubmitButton onClick={onSubmit} disabled={!currentAnswerId}>
          Zagłosuj teraz
        </SubmitButton>
      )}
    </div>
  );
};

export const PollWidget = ({ pollId }) => {
  const { data, error, isLoading } = useActivePoll({ pollId });

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <div>nie udało się pobrać ankiety</div>;
  }

  return (
    <WidgetWrapper>
      <Question>{data.question}</Question>
      <Form pollId={pollId} />
    </WidgetWrapper>
  );
}

export const PollsCardForm = ({ isPage }) => {
  const router = useRouter();
  const { pollId } = router.query;
  const { data, error, isLoading } = useActivePoll({ pollId });

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <div>nie udało się pobrać ankiety</div>;
  }

  const { id, title, question, comments_count } = data;

  if (isPage) {
    return (
      <>
        <Seo title={title} />
        <h1>{title}</h1>
        <p>{question}</p>
        <br />
        <Form pollId={pollId} />
        <br />
        <br />
        <br />
        <Comments contentId={id} contentType={COMMENT_CONTENT_TYPES.POLL} comments_count={comments_count} />
      </>
    );
  }

  return (
    <CardInnerWrapper>
      <Question>{data.question}</Question>
      <Form pollId={pollId} />
      <Link href={`/polls/${data.id}`} passHref>
        <External title="open full poll">
          <MdOpenInNew />
        </External>
      </Link>
    </CardInnerWrapper>
  );
};

export const PollsCard = () => (
  <Card data-margin>
    <CardHeader>Ankieta</CardHeader>
    <PollsCardForm />
  </Card>
);
