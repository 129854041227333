import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { prepareDefaultRange } from './Pagination.utils';

export const usePagination = ({ currentPage, maxPage }) =>
  useMemo(() => {
    const range = 2;
    const doubleRange = range * 2;

    const next = currentPage === maxPage ? null : currentPage + 1;
    const prev = currentPage === 1 ? null : currentPage - 1;

    let nextMany = currentPage + doubleRange + 1;
    if (nextMany >= maxPage) {
      nextMany = null;
    }
    let prevMany = currentPage - doubleRange - 1;
    if (prevMany <= 1) {
      prevMany = null;
    }

    const first = prevMany ? 1 : null;
    const last = nextMany ? maxPage : null;

    const pages = prepareDefaultRange(range)
      .map((item) => item + currentPage)
      .map((item) => {
        if (item <= 0) {
          return item + doubleRange + 1;
        }
        if (item > maxPage) {
          return item - doubleRange - (maxPage - currentPage + 1);
        }
        return item;
      }, [])
      .filter((item) => item >= 1 && item <= maxPage)
      .sort((a, b) => a - b);

    return { pages, next, prev, nextMany, prevMany, first, last };
  }, [maxPage, currentPage]);

export const useGetHref = (page) => {
  const router = useRouter();
  const { pathname, query } = router;
  const { tag } = query;

  if (['/', '/games', '/szukaj'].includes(pathname)) {
    return {
      pathname,
      query: { ...query, page },
    };
  }

  if (pathname.startsWith('/news')) {
    return `/news/${page}`;
  }

  if (pathname.startsWith('/categories')) {
    return {
      query: { ...query, page },
    };
  }

  if (pathname.startsWith('/admin')) {
    return {
      query: { ...query, page },
    };
  }

  if (pathname.startsWith('/tagi')) {
    return `/tagi/${tag}/${page}`;
  }
  console.warn('unsupported url in <Pagination/>');
  return '#';
};
