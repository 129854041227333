import styled from 'styled-components';

const maxWidth = '520px';

export const Card = styled.a`
  padding: 3px;
  margin: 10px 5px 10px 5px;
  display: flex;
  background-color: var(--bg-strong);
  border-radius: var(--br);
  overflow: hidden;
  text-decoration: none;
  min-height: 90px;
  transition: box-shadow 0.3s;
  &:hover {
    box-shadow: 0 0 0 2px var(--primary);
  }
  @media (max-width: ${maxWidth}) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 10px 5px;
  }
`;

export const ImageWrapper = styled.div`
  width: min(200px, 20vw);
  min-height: 90px;
  @media (max-width: ${maxWidth}) {
    width: 100%;
    height: 200px;
  }
  span {
    height: 100% !important;
  }
`;

export const Title = styled.h3`
  font-weight: 700;
  font-size: 20px;
  text-transform: uppercase;
  margin: 10px;
  text-align: left;
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 10px;
  min-width: 170px;
  @media (max-width: ${maxWidth}) {
    align-items: center;
  }
`;
