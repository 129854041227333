const translations = {
  'event obt': 'Otwarcie Beta',
  'event cbt': 'Zamknięcie Beta test',
  'event close': 'Zamknięcie',
  'event premier': 'Premiera',
  'event new_server': 'Nowy serwer',
  'event new_class': 'Nowa klasa',
  'event alpha_test': 'Alpha test',
  'event new_extension': 'Nowy dodatek',
  'event new_update': 'Nowy update',
  'event new_payment': 'Nowa płatność',
  'event others': 'Inne',
  'event early_access': 'Wczesny dostęp',
  'tab / description': 'Opis',
  'tab / screenshots': 'Screenshoty',
  'tab / video': 'Filmy',
  'tab / news': 'Newsy',
  'rate 5': '5 - Super',
  'rate 4': '4 - Dobra',
  'rate 3': '3 - Ujdzie',
  'rate 2': '2 - Słaba',
  'rate 1': '1 - Tragedia',
};

export const t = (text) => translations[text] || text;
