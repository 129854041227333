import styled from 'styled-components';

export const Wrapper = styled.a`
  display: block;
  margin: 15px 5px;
  border-radius: 8px;
  color: #ffffff;
  text-decoration: none;
  position: relative;
  box-shadow: 0 23px 41px rgba(0, 0, 0, 0.26);
  min-height: 200px;
  width: 100%;
  min-width: 280px;
  max-width: 370px;
  @media (max-width: 780px) {
    max-width: 100vw;
  }
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  overflow: hidden;
  transition: box-shadow 0.3s;
  box-shadow: 0 0 20px -2px black;
  outline: none;
  &:hover {
    box-shadow: 0 0 0 2px var(--primary);
  }
`;

export const ImageWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const PlayButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  height: 40px;
  width: 40px;
  font-size: 18px;
  box-shadow: 0 23px 23px rgba(0, 0, 0, 0.3);
  background-color: #ff2e2e;
  border-radius: 50%;
  position: absolute;
  top: 20px;
  right: 20px;
`;

export const Title = styled.h4`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 5px 10px;
  height: 85px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  /* text-shadow:-2px -2px 0 black,2px -2px 0 black,-2px 2px 0 black,2px 2px 0 black; */
  background-color: rgba(0, 0, 0, 0.6);
`;
