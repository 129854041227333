import { useState, useEffect, useMemo } from 'react';
import { Image } from 'components/Image';
import Link from 'next/link';
import { FaThumbsUp } from 'react-icons/fa';
import { updateVote, useGetChallange } from 'apis/others';
import { useAlert } from 'react-alert';
import { Comments } from 'components/Comments';
import { COMMENT_CONTENT_TYPES } from 'utils/constants';
import { useSession } from 'apis';
import { MdOpenInNew } from 'react-icons/md';
import { useMutation } from 'react-query';
import { Wrapper, WrapperGame, Title, Container, VS, LikeWrapper, Result, External } from './index.styles';
import { ChallangeSkeleton } from './ChallangeSkeleton';

const Provider = ({ challangeData, refetch, renderFullPage = false }) => {
  const {
    games_blue,
    games_red,
    blue_game_votes,
    red_game_votes,
    votes_count,
    id,
    challange_votes,
    title,
    description,
    comments_count,
  } = challangeData;
  const [active, setActive] = useState(null);
  const { data: session } = useSession();
  const alert = useAlert();

  useEffect(() => {
    setActive(challange_votes[0]?.game_id);
  }, [challange_votes]);

  const { mutate } = useMutation(updateVote, {
    onSuccess: () => {
      refetch();
    },
    onError: (error) => {
      alert.error(error);
    },
  });

  const handleLike = (game_id) => () => {
    if (game_id === active) return;
    if (session) {
      mutate({ challange_id: id, game_id });
    } else {
      alert.error('Musisz być zalogowany, aby oddać głos');
    }
  };

  const bluePercent = useMemo(
    () => ((blue_game_votes / votes_count) * 100 || 0).toFixed(2),
    [blue_game_votes, votes_count]
  );

  const redPercent = useMemo(
    () => ((red_game_votes / votes_count) * 100 || 0).toFixed(2),
    [red_game_votes, votes_count]
  );

  return (
    <Wrapper>
      {renderFullPage && (
        <h1>
          {games_blue.title} vs. {games_red.title}
        </h1>
      )}
      <h3>{title}</h3>
      <span>{description}</span>
      <Link href={`/challanges/${id}`} passHref>
        <External title="open full game duel">
          <MdOpenInNew />
        </External>
      </Link>
      <Container>
        <WrapperGame data-active={active === games_blue.id}>
          <Image src={games_blue.image} alt={games_blue.title} />
          <Link href={`/games/${games_blue.permalink}`} passHref>
            <Title target="_blank" title={games_blue.title}>
              {games_blue.title}
            </Title>
          </Link>
          <LikeWrapper onClick={handleLike(games_blue.id)} data-color="blue" aria-label="like left game">
            <FaThumbsUp />
          </LikeWrapper>
        </WrapperGame>

        <WrapperGame data-active={active === games_red.id}>
          <Image src={games_red.image} alt={games_red.title} />
          <Link href={`/games/${games_red.permalink}`} passHref>
            <Title target="_blank" title={games_red.title}>
              {games_red.title}
            </Title>
          </Link>
          <LikeWrapper onClick={handleLike(games_red.id)} data-color="red" aria-label="like right game">
            <FaThumbsUp />
          </LikeWrapper>
        </WrapperGame>
        <VS>VS</VS>
      </Container>
      <Result data-color="blue" result={bluePercent}>
        {bluePercent}% ({blue_game_votes})
      </Result>
      <Result data-color="red" result={redPercent}>
        {redPercent}% ({red_game_votes})
      </Result>
      <span>Liczba głosów: {votes_count}</span>
      {renderFullPage && (
        <Comments contentId={id} contentType={COMMENT_CONTENT_TYPES.CHALLANGE} comments_count={comments_count} />
      )}
    </Wrapper>
  );
};

export const Challange = ({ id, initialData, renderFullPage }) => {
  const { data, error, isLoading, refetch } = useGetChallange({ id, initialData });

  if (isLoading) {
    return <ChallangeSkeleton renderFullPage={renderFullPage} />;
  }

  if (error) {
    return <div>Nie udało się pobrać pojedynków gier</div>;
  }

  return <Provider challangeData={data} renderFullPage={renderFullPage} refetch={refetch} />;
};
