import Link from 'next/link';
import { MdChat } from 'react-icons/md';
import { formatDistanceToNow } from 'date-fns';
import { pl } from 'date-fns/locale';
import { FaShare } from 'react-icons/fa';
import { Wrapper, Image, Title, Split, Bottom } from './index.styles';

export const PostCard = ({ title, comments_count, image, created_at, permalink, url }) => {
  const formatedDate = formatDistanceToNow(new Date(created_at), { locale: pl, addSuffix: true });
  const openInNewTab = !!url;

  return (
    <Link href={`/news/zobacz/${permalink}`} passHref>
      <Wrapper titlt={title} target={openInNewTab ? '_blank' : null} rel={openInNewTab ? 'nofollow noreferrer' : null}>
        <Image src={image} alt={title} sizes="50vw" quality={90} layout="fill" />
        <Bottom>
          <Title>{title}</Title>
          <Split>
            <Split>
              {openInNewTab ? (
                <FaShare size={24} />
              ) : (
                <>
                  <MdChat fontSize={24} />
                  &nbsp;
                  {comments_count}
                </>
              )}
            </Split>
            <div>{formatedDate}</div>
          </Split>
        </Bottom>
      </Wrapper>
    </Link>
  );
};
