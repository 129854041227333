import styled from 'styled-components';

const mediaQuery = '700px';

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: ${mediaQuery}) {
    flex-direction: column;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Main = styled.main`
  min-height: 90vh;
`;

export const AsideWrapper = styled.aside`
  display: flex;
  width: 300px;
  flex-direction: column;
  @media (max-width: ${mediaQuery}) {
    width: 100%;
  }
`;

export const Divider = styled.div`
  width: 10px;
  @media (max-width: ${mediaQuery}) {
    display: none;
  }
`;
