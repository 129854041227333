import { GiCentaur } from '@react-icons/all-files/gi/GiCentaur';
import { GiRing } from '@react-icons/all-files/gi/GiRing';
import { GiRoundBottomFlask } from '@react-icons/all-files/gi/GiRoundBottomFlask';
import { GiShield } from '@react-icons/all-files/gi/GiShield';
import { GiSpiderMask } from '@react-icons/all-files/gi/GiSpiderMask';
import { GiUnlitBomb } from '@react-icons/all-files/gi/GiUnlitBomb';
import { GiWhiteTower } from '@react-icons/all-files/gi/GiWhiteTower';

const RANK_ICONS = {
  GiCentaur,
  GiRing,
  GiRoundBottomFlask,
  GiShield,
  GiSpiderMask,
  GiUnlitBomb,
  GiWhiteTower,
};

export { RANK_ICONS };
