import styled from 'styled-components';

export const RankDisplayStyle = styled.div`
  ${({ color }) => (color ? `color: ${color};` : null)}
  margin-right: ${({ isSmall }) => (isSmall ? '0' : '1rem')};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  svg {
    width: ${({ isSmall }) => (isSmall ? '1rem' : '2rem')};
    margin-right: ${({ isSmall }) => (isSmall ? '0rem' : '0.5rem')};
    height: ${({ isSmall }) => (isSmall ? '1rem' : '2rem')};
  }
`;
