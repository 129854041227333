import styled from 'styled-components';

export const PostsPillsWrapper = styled.div`
  max-width: 100%;
  display: flex;
  align-items: flex-start;
  flex-flow: row wrap;
`;

export const Pill = styled.span`
  white-space: nowrap;
  padding: 0 15px;
  text-transform: uppercase;
  border-radius: 9px;
  font-size: 12px;
  line-height: 20px;
  background-color: #3d4252;
  color: white !important;
  margin: 2px;
  display: inline-block;
  text-align: center;
  &[data-important='true'],
  &[data-variant='primary'] {
    background-color: #ffb718;
    color: black !important;
    font-weight: 900;
  }
`;

export const GamesWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
`;
