/* eslint import/no-cycle:"off" */

import { COMMENT_CONTENT_TYPES } from 'utils/constants';
import { SubsectionHeader } from 'components/SubsectionHeader';
import { Loading } from 'components/Loading';
import { useComments } from 'apis';
import PropTypes from 'prop-types';
import { CommentsWrapper } from './index.styles';
import { SingleCommentsWrapper } from './SingleCommentsWrapper';
import { TextCommentArea } from './TextCommentArea';

export const Comments = ({ contentId, contentType, comments_count }) => {
  const { data, error, isLoading } = useComments({ contentId, contentType });
  const isNestedComment = contentType === COMMENT_CONTENT_TYPES.COMMENT;

  return (
    <CommentsWrapper data-contentid={contentId} data-contenttype={contentType}>
      {!isNestedComment && (
        <>
          <SubsectionHeader> {comments_count} Komentarzy</SubsectionHeader>
          <TextCommentArea contentId={contentId} contentType={contentType} />
        </>
      )}
      {isLoading ? (
        <Loading data-center data-margin />
      ) : error ? (
        <div>Nie udało się pobrać komentarzy</div>
      ) : (
        data.map((comment) => (
          <SingleCommentsWrapper
            key={comment.id}
            contentId={contentId}
            contentType={contentType}
            isNestedComment={isNestedComment}
            comment={comment}
          />
        ))
      )}
    </CommentsWrapper>
  );
};

Comments.propTypes = {
  contentId: PropTypes.number.isRequired,
  contentType: PropTypes.oneOf(Object.values(COMMENT_CONTENT_TYPES)).isRequired,
};
