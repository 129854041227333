import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  color: white;
  font-size: 30px;
  & > div {
    margin: 5px;
  }
`;
