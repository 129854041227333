function ChatIcon(props) {
  return (
    <svg
      width={12}
      height={13}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.917 1.939a.583.583 0 00-.584.583v7.925l1.338-1.338c.11-.109.257-.17.412-.17h7a.583.583 0 00.583-.584V2.522a.583.583 0 00-.583-.583H1.917zM.679 1.284A1.75 1.75 0 011.917.772h8.166a1.75 1.75 0 011.75 1.75v5.833a1.75 1.75 0 01-1.75 1.75H3.325l-2.163 2.163a.583.583 0 01-.995-.413V2.522c0-.464.184-.91.512-1.238z"
        fill="#6EA1E0"
      />
    </svg>
  );
}

export default ChatIcon; 