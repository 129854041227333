import styled from 'styled-components';
import { Image } from 'components/Image';

export const Card = styled.div`
  background-color: var(--opacity-v-light);
  border-radius: 4px;
  padding: 14px;
  margin: 5px;
  position: relative;
  @media (max-width: 600px) {
    padding: 5px;
  }
  &[data-margin='true'] {
    margin: 5px 0;
  }
`;

export const CardItem = styled.a`
  text-decoration: none;
  font-size: 18px;
  padding: 6px;
  display: flex;
  align-items: center;
  background-color: var(--bg-strong);
  border-radius: var(--br);
  overflow: hidden;
  transition: box-shadow 0.3s;
  margin: 5px 0;
  &:hover {
    box-shadow: 0 0 0 2px var(--primary);
  }
`;

export const CardList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
`;

export const CardHeader = styled.h4`
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  margin-bottom: 12px;
`;

export const CardInnerWrapper = styled.div`
  padding: 8px;
  background-color: var(--bg-strong);
  border-radius: 4px;
`;

export const CardImage = styled(Image)`
  height: 60px !important;
  width: 70px !important;
  border-radius: 4px;
  margin-right: 5px;
`;
