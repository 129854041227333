import { RankDisplayStyle } from './index.style';
import { RANK_ICONS } from './RankDisplay.constants';

export const RankDisplay = ({ rankData: { name, icon, color }, isSmall }) => {
  const RankSpecificIcon = RANK_ICONS[icon];
  return (
    <RankDisplayStyle color={color} isSmall={isSmall}>
      {icon && <RankSpecificIcon />}
      <div>{name}</div>
    </RankDisplayStyle>
  );
};
