function DateIcon(props) {
   return (
      <svg
         width={14}
         height={15}
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
         {...props}
      >
         <g
            clipPath="url(#prefix__clip0_501_485)"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#6EA1E0"
         >
            <path d="M2.917 3.522a.583.583 0 00-.584.583v8.167c0 .322.261.583.583.583h8.167a.583.583 0 00.583-.583V4.105a.583.583 0 00-.583-.583H2.917zm-1.75.583c0-.966.783-1.75 1.75-1.75h8.166c.967 0 1.75.784 1.75 1.75v8.167a1.75 1.75 0 01-1.75 1.75H2.917a1.75 1.75 0 01-1.75-1.75V4.105z" />
            <path d="M9.333 1.189c.322 0 .584.26.584.583v2.333a.583.583 0 11-1.167 0V1.772c0-.322.261-.583.583-.583zM4.667 1.189c.322 0 .583.26.583.583v2.333a.583.583 0 11-1.167 0V1.772c0-.322.262-.583.584-.583zM1.167 6.439c0-.323.26-.584.583-.584h10.5a.583.583 0 110 1.167H1.75a.583.583 0 01-.583-.583z" />
         </g>
         <defs>
            <clipPath id="prefix__clip0_501_485">
               <path fill="#fff" transform="translate(0 .605)" d="M0 0h14v14H0z" />
            </clipPath>
         </defs>
      </svg>
   );
}

export default DateIcon;