import { useQuery } from "react-query"
import striptags from "striptags"
import Image from "next/image"
import fetcher from "../../utils/fetcher"
import { MetadataItem, MetadataWrapper, OuterWrapper, Teaser, TextWrapper, ThumbWrapper, Title, StyledLi, StyledUl, StyledAnchor } from "./PopularNews.styles"
import { SubsectionHeader } from "../SubsectionHeader"
import { Loading } from "../Loading"
import EyeIcon from "../Icons/Eye"
import ChatIcon from "../Icons/Chat"
import DateIcon from "../Icons/Date"

export const PopularNews = () => {

  const { isLoading, error, data: posts } = useQuery(["popularNews"], () =>
    fetcher("/api/popularNews")
  )

  if (isLoading) return <Loading />

  if (!posts || posts?.length === 0 || error) return null

  return (
    <OuterWrapper>
      <SubsectionHeader>Ostatnio popularne</SubsectionHeader>
      <StyledUl>
        {posts.map(post => (
          <StyledLi key={post.title}>
            <StyledAnchor href={`${process.env.NEXT_PUBLIC_WEB_URL}/news/zobacz/${post.permalink}`}>
              <ThumbWrapper>
                <Image
                  src={post.image}
                  alt={post.title}
                  layout="fill"
                />
              </ThumbWrapper>
              <TextWrapper>
                <Title>{post.title.slice(0, 50)}...</Title>
                <Teaser>
                  {striptags(post.value).slice(0, 100)}...
                </Teaser>
                <MetadataWrapper>
                  <div className="stats">
                    <MetadataItem><EyeIcon />{post.counter}</MetadataItem>
                    <MetadataItem><ChatIcon />{post.comments_count}</MetadataItem>
                  </div>
                  <div className="date">
                    <MetadataItem>
                      <DateIcon />
                      {new Date(post.created_at).toLocaleDateString("pl", { year: 'numeric', month: 'long', day: 'numeric' })}
                    </MetadataItem>
                  </div>
                </MetadataWrapper>
              </TextWrapper>
            </StyledAnchor>
          </StyledLi>
        ))}
      </StyledUl>
    </OuterWrapper>
  )
}
