import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      height="1em"
      width="1em"
    >
      <path
        d="M2547 4800c-115-20-89 4-1163-1072C564 2906 369 2706 351 2666c-33-72-44-146-31-213 24-134-15-90 1024-1129 725-726 975-970 1016-993 52-29 61-31 164-31 101 0 112 2 165 30 46 25 247 221 1058 1033 684 685 1008 1015 1022 1044 52 104 52 99 49 1146l-3 972-32 66c-39 80-111 150-191 187l-57 27-970 1c-533 1-992-2-1018-6zm1931-1280l2-945-967-967c-533-533-975-968-983-968-20 0-1880 1860-1880 1880 0 8 435 450 967 983l968 967 945-2 945-3 3-945z"
        transform="matrix(.1 0 0 -.1 0 512)"
      />
      <path
        d="M3712 4032c-214-77-233-375-30-476 142-71 319 7 364 160 58 201-137 386-334 316zM2118 3582c-296-267-328-302-280-316 17-5 387-119 590-182 63-19 124-34 135-32 25 4 26 19 3 137l-14 74 86-6c281-18 524-207 607-470 46-144 54-305 23-437-17-70-70-179-122-247-26-35-46-67-44-72 1-5 52-59 112-122 89-92 111-111 125-102 45 28 135 154 190 267 86 176 114 296 113 497-2 430-234 790-617 961-125 56-239 81-400 87l-139 6-18 99c-16 89-30 127-47 126-3-1-140-121-303-268zM1692 3178c-165-209-242-426-242-682 0-304 106-570 306-770 199-199 464-306 760-306h92l17-92c21-122 23-128 43-128 21 0 614 538 609 553-2 6-10 14-18 19-28 16-732 227-740 222-13-8-10-59 6-134 8-36 14-69 15-74 0-16-167 3-244 28-301 95-486 359-486 690 0 175 48 302 173 459 19 23 26 41 20 49-20 31-232 228-246 228-8 0-38-28-65-62z"
        transform="matrix(.1 0 0 -.1 0 512)"
      />
    </svg>
  );
}

export default Icon;
