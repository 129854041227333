import Link from 'next/link';
import { PostsPillsWrapper, Pill, GamesWrapper } from './index.styles';

export const PillsPostsWrapper = ({ items }) => (
  <PostsPillsWrapper>
    {items.map(({ title, href, important }) => {
      if (title && href) {
        return (
          <Link href={href} key={title + important} passHref>
            <Pill as="a" title={title} data-important={important}>
              {title}
            </Pill>
          </Link>
        );
      }
      return (
        <Pill key={title + important} data-important={important}>
          {title}
        </Pill>
      );
    })}
  </PostsPillsWrapper>
);

export const PillsGamesWrapper = ({ games_genres }) => (
  <GamesWrapper>
    {games_genres.map(({ genres: { id, title } }, index) => (
      <Pill key={id} data-variant={index ? 'normal' : 'primary'}>
        {title}
      </Pill>
    ))}
  </GamesWrapper>
);
