function EyeIcon(props) {
   return (
      <svg
         width={14}
         height={15}
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
         {...props}
      >
         <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.249 7.605A10.895 10.895 0 002.686 9.54C3.731 10.655 5.191 11.69 7 11.69c1.809 0 3.27-1.035 4.314-2.15a10.897 10.897 0 001.437-1.934 10.897 10.897 0 00-1.437-1.934C10.269 4.556 8.809 3.52 7 3.52c-1.809 0-3.27 1.035-4.314 2.15a10.895 10.895 0 00-1.437 1.934zm12.168 0l.521-.261v-.002l-.003-.004-.006-.013a3.85 3.85 0 00-.112-.206 12.063 12.063 0 00-1.652-2.246C11.022 3.654 9.275 2.355 7 2.355S2.978 3.654 1.835 4.873A12.06 12.06 0 00.183 7.119a7.204 7.204 0 00-.112.206l-.006.013-.002.004-.001.001.521.262-.521-.26a.583.583 0 000 .521l.521-.26-.521.26v.002l.003.004.006.013a4.377 4.377 0 00.112.207 12.058 12.058 0 001.652 2.246c1.143 1.219 2.89 2.517 5.165 2.517s4.022-1.299 5.165-2.518a12.061 12.061 0 001.652-2.245 7.185 7.185 0 00.112-.207l.006-.013.002-.004.001-.001-.521-.262zm0 0l.521.261a.584.584 0 000-.522l-.521.261z"
            fill="#6EA1E0"
         />
         <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 6.439a1.167 1.167 0 100 2.333 1.167 1.167 0 000-2.333zM4.667 7.605a2.333 2.333 0 114.666 0 2.333 2.333 0 01-4.667 0z"
            fill="#6EA1E0"
         />
      </svg>
   );
}

export default EyeIcon;