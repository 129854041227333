import * as gamesOptions from 'utils/gamesOptions';
import { Wrapper } from './GameIcons.styles';

export const GameIcons = ({ game }) => {
  const { mode, payment, games_views, games_platforms } = game;

  const options = {
    PAYMENT_OPTIONS: [payment],
    MODE_OPTIONS: [mode],
    VIEW_OPTIONS: games_views.map((v) => v.view_id),
    PLATFORM_OPTIONS: games_platforms.map((v) => v.platform_id),
  };

  const icons = Object.entries(options)
    .map(([option, arrIcons]) => arrIcons.map((index) => gamesOptions[option].find((v) => v.value === index)))
    .flat()
    .filter((v) => v)
    .map(({ icon, label }) => (
      <div title={label} key={label}>
        {icon}
      </div>
    ));

  return <Wrapper>{icons}</Wrapper>;
};
