import Link from 'next/link';
import { Wrapper, Slider, Title } from './SliderContainer.styles';
import { Image } from '../Image';

export const SliderContainer = ({ slides }) => (
  <Wrapper>
    {slides.map(({ id, image = '/img/default_slider.png', title = 'some Slider', url = '#' }) => (
      <Link href={url} passHref key={id}>
        <Slider title={title}>
          <Image src={image} alt={title} width={280} height={480} quality={90} layout="fixed" />
          <Title>
            <span>{title}</span>
          </Title>
        </Slider>
      </Link>
    ))}
  </Wrapper>
);
