import styled from 'styled-components';
import Image from 'next/image';
import Link from 'next/link';

const Avatar = styled.a`
  border-radius: 100%;
  display: block;
  width: 50px;
  flex-shrink: 0;
  height: 50px;
  margin: 5px;
  overflow: hidden;
  background-color: var(--opacity-strong);
  &[data-small='true'] {
    width: 30px;
    height: 30px;
  }
`;

export const AvatarArea = ({ author, ...restProps }) => {
  const { avatar, id, login } = author;
  return (
    <Link href={`/users/${id}`} passHref>
      <Avatar title={login} {...restProps}>
        <Image src={avatar} width={50} height={50} objectFit="cover" objectPosition="center" />
      </Avatar>
    </Link>
  );
};
