import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import { Container, WrapperGame } from '../index.styles';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const ChallangeSkeleton = ({ renderFullPage }) => (
  <Wrapper>
    {renderFullPage && <Skeleton width={500} height={50} />}
    <br />
    <Skeleton width={250} height={25} />
    <Container>
      <WrapperGame>
        <Skeleton height={200} />
      </WrapperGame>
      <WrapperGame>
        <Skeleton height={200} />
      </WrapperGame>
    </Container>
    <Skeleton width={250} />
    <Skeleton width={400} />
    <Skeleton width={150} />
  </Wrapper>
);
