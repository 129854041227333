import { Pagination } from 'components/Pagination';
import { Button } from 'components/Button';
import Link from 'next/link';
import { usePosts } from 'apis';
import { Loading } from 'components/Loading';
import { useRouter } from 'next/router';
import { PostCardsWrapper, PostsWrapperWrapper } from './index.styles';
import { PostPage } from './PostPage';

export const Posts = ({ reverse, redirectToNews, includeSearch }) => {
  const router = useRouter();
  const { page, q, tag } = router.query;
  const { data: postData, error, isLoading } = usePosts({ q: includeSearch ? q : '', page, tag });

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <div>{error.message}</div>;
  }

  const { pages, data } = postData;
  const { max, current } = pages;

  if (!data.length) {
    return <div>Brak postów</div>;
  }

  return (
    <PostsWrapperWrapper data-reverse={reverse}>
      <PostCardsWrapper>
        <PostPage postData={data} />
      </PostCardsWrapper>
      {redirectToNews ? (
        <Link href="/news/2" passHref>
          <a title="Zobacz więcej postów">
            <Button data-margin as="div">
              Zobacz więcej postów
            </Button>
          </a>
        </Link>
      ) : (
        <Pagination currentPage={current} maxPage={max} />
      )}
    </PostsWrapperWrapper>
  );
};
