import { Fragment } from 'react';
import { AdsBlock } from 'components/AdsBlock';
import { GameCard } from 'components/GameCard';
import { Wrapper, Center } from './GameCardsWrapper.styles';

export const GameCardsWrapper = ({ games, renderAds }) => {
  if (games.length) {
    return (
      <Wrapper>
        {games.map((game, i) => (
          <Fragment key={game.id}>
            <GameCard game={game} />
            {renderAds && i === 7 && <AdsBlock id="sekcja_bill_srodek_1" />}
            {renderAds && i === 15 && <AdsBlock id="sekcja_bill_srodek_2" />}
          </Fragment>
        ))}
      </Wrapper>
    );
  }
  return <Center>Nie znaleziono gier</Center>;
};
