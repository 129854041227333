import { FiShare2 } from 'react-icons/fi';
import { Iso, Mmo, Ftp, Btp, Ptp } from 'components/Icons';
import { MdComputer } from 'react-icons/md';
import { BiWebcam } from 'react-icons/bi';
import { FaGlobeAmericas, FaMobileAlt, FaVrCardboard, FaPlaystation, FaXbox, FaGlasses } from 'react-icons/fa';

export const ACCESS_OPTIONS = [
  { value: 0, label: 'Otwarty' },
  { value: 1, label: 'Zamknięty' },
  { value: 2, label: 'Otwarta beta' },
  { value: 3, label: 'Zamknięta beta' },
  { value: 4, label: 'Wczesny dostęp' },
  { value: 5, label: 'Ogłoszona' },
];

export const PAYMENT_OPTIONS = [
  {
    value: 0,
    label: 'Free-To-Play',
    icon: <Ftp />,
  },
  {
    value: 1,
    label: 'Pay-To-Play',
    icon: <Ptp />,
  },
  {
    value: 2,
    label: 'Buy-To-Play',
    icon: <Btp />,
  },
];

export const MODE_OPTIONS = [
  {
    value: 0,
    label: 'Mmo',
    icon: <Mmo />,
  },
  {
    value: 1,
    label: 'Sieć',
    icon: <FiShare2 />,
  },
];

export const VIEW_OPTIONS = [
  {
    value: 1,
    label: 'FPP',
    icon: <FaGlasses />,
  },
  {
    value: 2,
    label: 'TPP',
    icon: <BiWebcam />,
  },
  {
    value: 3,
    label: 'ISO',
    icon: <Iso />,
  },
];

export const PLATFORM_OPTIONS = [
  {
    value: 1,
    label: 'PC',
    icon: <MdComputer />,
  },
  {
    value: 2,
    label: 'WWW',
    icon: <FaGlobeAmericas />,
  },
  {
    value: 3,
    label: 'MOBILE',
    icon: <FaMobileAlt />,
  },
  {
    value: 4,
    label: 'PS',
    icon: <FaPlaystation />,
  },
  {
    value: 5,
    label: 'Xbox',
    icon: <FaXbox />,
  },
  {
    value: 6,
    label: 'VR',
    icon: <FaVrCardboard />,
  },
];
