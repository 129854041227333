/* eslint react/no-danger:"off" */
import { useState } from 'react';
import Calendar from 'react-calendar';
import { Loading } from 'components/Loading';
import { Card } from 'components/Card';
import { useCalendar } from 'apis';
import { t } from 'utils/t';
import { EVENTS_CATEGORIES } from 'components/Inputs/index.constants';
import { Wrapper, Event, Bullet, BulletsWrapper, LoadingWrapper } from './index.styles';

export const CalendarEvents = () => {
  const [date, setDate] = useState(new Date().toISOString());
  const { data, isLoading, error } = useCalendar({ date });
  const [selectedEvent, setSelectedEvent] = useState(new Date());

  const showEventsInThisDay = (day) =>
    (data?.data ?? []).filter((v) => new Date(v.start_at).toLocaleDateString() === new Date(day).toLocaleDateString());

  return (
    <Card data-margin>
      <Wrapper>
        <Calendar
          onChange={setSelectedEvent}
          value={selectedEvent}
          onActiveStartDateChange={({ activeStartDate }) => setDate(activeStartDate.toISOString())}
          tileContent={(singleDate) => (
            <BulletsWrapper>
              {showEventsInThisDay(singleDate.date).map(({ color, id }) => (
                <Bullet key={id} bgc={color} />
              ))}
            </BulletsWrapper>
          )}
          locale="pl-PL"
        />
        {showEventsInThisDay(selectedEvent).map(({ name, category, id, color, opis }) => {
          const Title = () => (
            <>
              <Bullet bgc={color} />
              {`  `}
              {t(`event ${EVENTS_CATEGORIES[category || 10].label}`)}: {name}
            </>
          );
          if (!opis) {
            return (
              <Event key={id}>
                <Title />
              </Event>
            );
          }
          return (
            <Event key={id}>
              <details>
                <summary>
                  <Title />
                </summary>
                <div dangerouslySetInnerHTML={{ __html: opis }} />
              </details>
            </Event>
          );
        })}
        {isLoading && (
          <LoadingWrapper>
            <Loading />
          </LoadingWrapper>
        )}
        <div>{error}</div>
      </Wrapper>
    </Card>
  );
};
