import { VideoCard } from 'components/VideoCard';
import { SubsectionHeader } from 'components/SubsectionHeader';
import styled from 'styled-components';
import { Card } from 'components/Card';
import { Loading } from 'components/Loading';
import { useCachedData } from 'apis';

const VideoCardsWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: space-between;
`;

const Provider = () => {
  const { data, error, isLoading } = useCachedData({ type: 'recentYoutubeVideos' });

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <div>{error.message}</div>;
  }

  return (
    <VideoCardsWrapper>
      {data.map((video) => (
        <VideoCard key={video.yt_id} {...video} />
      ))}
    </VideoCardsWrapper>
  );
};

export const RecentYoutubeVideos = () => (
  <Card as="section" data-margin>
    <SubsectionHeader>Najnowsze filmy z naszego YouTube</SubsectionHeader>
    <Provider />
  </Card>
);
