import styled from 'styled-components';

export const Wrapper = styled.div`
  text-align: center;
  text-decoration: none;
  position: relative;
  abbr {
    text-decoration: none;
  }

  button,
  .react-calendar__month-view__weekdays__weekday {
    border: 2px solid var(--bg-light);
  }
  .react-calendar__month-view__days__day {
    padding: 0;
    background-color: var(--bg-medium);
  }

  .react-calendar__tile--now {
    background-color: rgba(231, 31, 31, 0.4);
  }
  .hasEvent {
    background-color: rgba(31, 114, 231, 0.6);
  }
  .react-calendar__tile--active {
    background-color: rgba(31, 114, 231, 1);
  }
  .react-calendar__month-view__weekdays__weekday {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & .react-calendar__tile,
  .react-calendar__month-view__weekdays__weekday {
    height: 50px;
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    background-color: var(--opacity-light);
  }
  .react-calendar__month-view__weekdays,
  .react-calendar__navigation__prev2-button,
  .react-calendar__navigation__next2-button {
    display: none !important;
  }
  .react-calendar__navigation__arrow {
    font-size: 30px;
  }
  .react-calendar__navigation {
    font-size: 20px;
    margin-bottom: 3px;
  }
`;

export const Event = styled.div`
  text-align: left;
  width: 100%;
  display: block;
  margin: 10px 0;
  word-break: break-all;
  a {
    color: white;
  }
`;

export const Bullet = styled.div`
  width: 7px;
  height: 7px;
  margin: 2px;
  border-radius: 10px;
  background-color: ${({ bgc }) => bgc || 'white'};
  display: inline-block;
`;

export const BulletsWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
`;
