import styled from "styled-components"

export const OuterWrapper = styled.section`
   margin: 5px 0;
   padding: 14px;
   background: var(--opacity-v-light);
`

export const StyledUl = styled.ul`
   display: grid;
   grid-template-columns: 1fr 1fr;
   gap: 1rem;

   @media (max-width: 1080px) {
      grid-template-columns: 1fr;
   };
`

export const StyledLi = styled.li`
   list-style: none;
`

export const StyledAnchor = styled.a`
   position: relative;
   display: grid;
   grid-template-columns: 30% 70%;
   height: 100%;
   padding: 15px;
   background: #121725;
   border-radius: 4px;
   transition: box-shadow .3s;
   background: var(--bg-strong);
   color: var(--font);

   &::before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: inherit;
      box-shadow: 0 0 0 2px var(--primary);
      opacity: 0;
      transition: opacity .3s ease-out;
   }

   &:hover::before {
      opacity: 1;
   }
   
   @media (max-width: 440px) {
       grid-template-columns: 1fr;
   };
`

export const ThumbWrapper = styled.div`
   position: relative;
   min-height: 150px;
   padding: 0;
   margin: 0;

   img {
      border-radius: 4px;
      object-fit: cover;
   }
`

export const TextWrapper = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   padding: inherit;
`

export const Title = styled.h3`
   margin-bottom: 10px;
`

export const Teaser = styled.p`
   color: var(--font-lighter);
   margin-bottom: 10px;
`

export const MetadataWrapper = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: space-between;

   .stats {
      display: flex;
      flex-direction: row;

      div:first-child {
         margin-right: 1rem;
      }
   }
`

export const MetadataItem = styled.div`
   display: flex;
   flex-direction: row;
   align-items: center;


   svg {
      padding: 0;
      margin-right: .25rem;
   }
`
