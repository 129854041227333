import { RankDisplay } from 'components/RankDisplay/RankDisplay';
import Link from 'next/link';
import styled from 'styled-components';

const Top = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  line-height: 15px;
  margin: 5px 40px 5px 0;
  color: var(--opacity-strong);
`;

const DateSpan = styled.span`
  margin: 3px 0;
`;

const Name = styled.a`
  font-weight: bold;
  font-size: 16px;
  margin-right: 15px;
  color: var(--font);
`;

export const HeaderArea = ({ author, created_at }) => (
  <Top>
    <Link href={`/users/${author.id}`} passHref>
      <Name title={author.login}>{author.login}</Name>
    </Link>
    {!author.rank.hidden && <RankDisplay rankData={author.rank} />}
    <DateSpan>
      {Intl.DateTimeFormat('pl', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      }).format(new Date(created_at))}
    </DateSpan>
  </Top>
);
