import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      height="1em"
      width="1em"
    >
      <path
        d="M2547 4800c-115-20-89 4-1163-1072C564 2906 369 2706 351 2666c-33-72-44-146-31-213 24-134-15-90 1024-1129 725-726 975-970 1016-993 52-29 61-31 164-31 101 0 112 2 165 30 46 25 247 221 1058 1033 684 685 1008 1015 1022 1044 52 104 52 99 49 1146l-3 972-32 66c-39 80-111 150-191 187l-57 27-970 1c-533 1-992-2-1018-6zm1931-1280l2-945-967-967c-533-533-975-968-983-968-20 0-1880 1860-1880 1880 0 8 435 450 967 983l968 967 945-2 945-3 3-945z"
        transform="matrix(.1 0 0 -.1 0 512)"
      />
      <path
        d="M3712 4032c-214-77-233-375-30-476 142-71 319 7 364 160 58 201-137 386-334 316zM1417 3132l-357-357 65-65 65-65 148 148 147 147 77-77 78-78 65 65 65 64-75 76-75 75 83 83 82 82 85-85c47-47 89-85 95-85s37 27 70 60l59 60-149 154c-83 84-155 154-160 155-6 0-171-160-368-357zM1885 2665l-360-353 60-65c33-36 64-65 70-66 5 0 75 64 155 144l146 146 24-26 24-26-147-140c-81-77-147-146-147-153 0-14 111-126 125-126 6 0 79 69 163 153l152 152v105h105l99 99 98 98 5 102 6 103-104 104c-57 57-106 104-109 104-3-1-167-160-365-355zm393 72l22-23-83-82-83-83-24 26-24 26 79 79c44 44 82 80 86 80 3 0 15-10 27-23zM2352 2197l-362-362 155-155 155-155 65 65 65 65-85 85c-47 47-85 90-85 95s35 45 77 87l78 78 77-77 78-78 67 68 68 67-78 78-77 77 83 83 82 82 90-90 90-90 65 65 65 65-155 155-155 155-363-363zM2815 1730l-360-360 155-155 155-155 65 65 65 65-90 90-90 90 83 82 82 83 78-78 78-77 64 65 64 65-72 73c-39 40-72 77-72 82s35 45 77 87l78 78 90-89 90-90 69 66 69 65-154 154c-85 85-156 154-159 154s-167-162-365-360z"
        transform="matrix(.1 0 0 -.1 0 512)"
      />
    </svg>
  );
}

export default Icon;
