/* eslint import/no-cycle:"off" */
import { useState } from 'react';
import { COMMENT_CONTENT_TYPES } from 'utils/constants';
import { useSession, useComments } from 'apis';
import { useAlert } from 'react-alert';
import { AvatarArea } from 'components/AvatarArea';
import { HeaderArea } from './HeaderArea';
import { SettingArea } from './SettingArea';
import { LikesArea } from './LikesArea';
import { TextCommentArea } from '../TextCommentArea';
import { Comments } from '../index';
import { CommentsWrapper, ShowMoreButton, ItemsWrapper, Bottom, Text, AddCommentButton, EditInfo } from './index.style';

export const SingleCommentsWrapper = (props) => {
  const { contentId, contentType, isNestedComment, comment } = props;
  const { author, created_at, edits, value, id, hidden_at, points, commentpoints, comments_count, reported } = comment;

  const [openNested, setOpenNested] = useState(false);
  const { data: nestedComments } = useComments(
    { contentId: id, contentType: COMMENT_CONTENT_TYPES.COMMENT },
    { enabled: openNested }
  );
  const [openTextarea, setOpenTextarea] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const { data: session } = useSession();
  const alert = useAlert();

  const reply = () => {
    if (session) {
      setOpenTextarea((v) => !v);
    } else {
      alert.error('Musisz być zalogowany');
    }
  };

  const answersCount = nestedComments?.length || comments_count;

  return (
    <CommentsWrapper data-nested={isNestedComment} data-commentid={id}>
      <AvatarArea author={author} data-small={isNestedComment} />
      <ItemsWrapper>
        <HeaderArea author={author} created_at={created_at} />
        {isEditing ? (
          <TextCommentArea
            contentId={contentId}
            contentType={contentType}
            commentValue={value}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            commentId={id}
          />
        ) : (
          <Text>{value}</Text>
        )}
        {!!edits && <EditInfo>edytowany {edits} razy</EditInfo>}
        <Bottom>
          <LikesArea points={points} commentpoints={commentpoints} commentId={id} />
          <AddCommentButton onClick={reply}>Odpowiedz</AddCommentButton>
        </Bottom>
        {openTextarea && (
          <TextCommentArea
            contentId={isNestedComment ? contentId : id}
            contentType={COMMENT_CONTENT_TYPES.COMMENT}
            commentValue={`@${author.login} `}
            commentId={id}
            setOpenNested={setOpenNested}
          />
        )}
        {!isNestedComment && !!answersCount && (
          <ShowMoreButton onClick={() => setOpenNested((v) => !v)}>
            &rarr; {openNested ? 'Ukryj' : 'Zobacz'} {answersCount} odpowiedzi
          </ShowMoreButton>
        )}
        {openNested && <Comments contentId={id} contentType={COMMENT_CONTENT_TYPES.COMMENT} />}
      </ItemsWrapper>
      <SettingArea
        commentId={id}
        reported={reported}
        contentId={contentId}
        contentType={contentType}
        hiddenAt={hidden_at}
        setIsEditing={setIsEditing}
        author={author}
      />
    </CommentsWrapper>
  );
};
