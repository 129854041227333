import styled from 'styled-components';

export const TextMessageAreaWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 5px 0;
  position: relative;
  background-color: var(--opacity-v-light);
  overflow: hidden;
`;

export const TextArea = styled.span`
  padding: 7px 55px 7px 7px;
  font-size: 15px;
  width: 100%;
  min-height: 50px;
  margin: 5px;
  box-sizing: border-box;
  line-height: 1.2;
  border: none;
  outline: none;
  overflow: hidden;
  background-color: var(--bg-medium);
  &[contenteditable]:empty::before {
    content: attr(data-placeholder);
  }
`;

export const SendButton = styled.button`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 5px;
  font-size: 30px;
  color: var(--font);
  top: 5px;
  right: 5px;
  transition: background-color 0.3s;
  background-color: transparent;
  &:hover {
    background-color: var(--opacity-medium);
  }
`;

export const NotLoginInfo = styled.button`
  margin: 10px 20px;
  font-size: 20px;
  border: none;
`;
