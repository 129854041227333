import Link from 'next/link';
import styled from 'styled-components';

const Wrapper = styled.div`
  flex-direction: column;
  margin: 14px auto;
  background-color: var(--opacity-light);
  display: var(--display-ads);
  max-width: 970px;
  width: 100%;
`;

const Ad = styled.div`
  min-height: 215px;
  width: 100%;
  @media (max-width: 1060px) {
    min-height: 340px;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const Redirect = styled.a`
  width: 100%;
  padding: 5px;
  text-align: center;
`;

export const AdsBlock = (props) => (
  <Wrapper>
    <Ad {...props} />
    <Link href="/premium" passHref>
      <Redirect>Ukryj reklamy</Redirect>
    </Link>
  </Wrapper>
);
