import { FaPlay } from 'react-icons/fa';
import { Image } from 'components/Image';
import { PlayButton, Title, Wrapper, ImageWrapper } from './VideoCard.styles';

export const VideoCard = ({ title, yt_id }) => {
  const href = `https://youtu.be/${yt_id}`;
  const image = `https://img.youtube.com/vi/${yt_id}/hqdefault.jpg`;
  return (
    <Wrapper href={href} title={title} rel="nofollow noopener" target="_blank">
      <ImageWrapper>
        <Image src={image} alt="" />
      </ImageWrapper>
      <PlayButton>
        <FaPlay />
      </PlayButton>
      <Title>{title}</Title>
    </Wrapper>
  );
};
