import styled from 'styled-components';

export const Title = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 15px;
  font-size: 25px;
  height: 130px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  & > span {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

export const Slider = styled.a`
  background-color: var(--opacity-light);
  position: relative;
  height: 400px;
  margin: 10px 5px;
  flex: 1;
  min-width: 280px;
  transition: box-shadow 0.3s;
  border-radius: var(--br);
  overflow: hidden;
  &:hover {
    box-shadow: 0 0 0 2px var(--primary);
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  overflow-x: auto;
`;
