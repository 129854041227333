import styled from 'styled-components';

export const CommentsWrapper = styled.div`
  display: flex;
  margin: 5px 0px;
  padding: 3px;
  position: relative;
  background-color: var(--bg-medium);
  border-radius: var(--br);
`;

export const ShowMoreButton = styled.button`
  border: none;
  padding: 7px;
  text-align: left;
  margin: 5px;
  border-radius: 5px;
`;

export const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 5px;
`;

export const Bottom = styled.div`
  display: flex;
  align-items: center;
`;

export const Text = styled.div`
  line-height: 1.65em;
  word-break: break-word;
  white-space: pre-wrap;
  text-align: left;
  margin: 6px 0px 6px 0px;
`;

export const AddCommentButton = styled.button`
  border: none;
  padding: 0px 10px;
  margin: 0 3px;
  border-radius: 4px;
  transition: background-color 0.3s;
  text-transform: uppercase;
  word-wrap: break-word;
  background-color: var(--bg-strong);
  &:hover {
    background-color: var(--opacity-medium);
  }
`;

export const EditInfo = styled.div`
  font-size: 11px;
  color: var(--opacity-strong);
  line-height: 11px;
`;
