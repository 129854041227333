import Link from 'next/link';
import { Loading } from 'components/Loading';
import { Card, CardHeader, CardList, CardItem, CardImage } from 'components/Card';
import { useCachedData } from 'apis';

const Provider = () => {
  const { data, error, isLoading } = useCachedData({ type: 'upcomingPremieres' });

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <div>{error.message}</div>;
  }

  return data.map(({ permalink, id, image, title }) => (
    <li key={id}>
      <Link href={`/games/${permalink}`} passHref>
        <CardItem title={title}>
          <CardImage src={image} alt={`${title} image`} width={60} height={60} layout="responsive" />
          <h4>{title}</h4>
        </CardItem>
      </Link>
    </li>
  ));
};
export const UpcomingPremieresCard = () => (
  <Card as="nav" data-margin>
    <CardHeader>Premiery</CardHeader>
    <CardList>
      <Provider />
    </CardList>
  </Card>
);
