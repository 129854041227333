import { Fragment } from 'react';
import { AdsBlock } from 'components/AdsBlock';
import { PostCard } from './PostCard';

export const PostPage = ({ postData }) =>
  postData.map((post, i) => (
    <Fragment key={post.id}>
      <PostCard {...post} />
      {i === 1 && <AdsBlock id="sg_bill_srodek_1" />}
      {i === 7 && <AdsBlock id="sg_bill_srodek_2" />}
    </Fragment>
  ));
