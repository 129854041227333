import styled from 'styled-components';
import { CardInnerWrapper as CardInnerWrapperComponent } from 'components/Card';
import { Button } from 'components/Button';

export const External = styled.a`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  font-size: 30px;
`;

export const SubmitButton = styled(Button)`
  margin: 5px auto;
`;

export const Question = styled.h5`
  font-size: 15px;
  line-height: 21px;
  color: var(--font);
  margin: 5px 0 10px 0;
`;

export const CardInnerWrapper = styled(CardInnerWrapperComponent)`
  padding: 10px 14px 8px;
  display: flex;
  flex-direction: column;
`;

export const WidgetWrapper = styled(CardInnerWrapperComponent)`
  padding: 10px 14px 8px;
  width: 70%;
  display: flex;
  flex-direction: column;
`;

export const AnswerLabel = styled.label`
  background-color: var(--opacity-light);
  border-radius: 4px;
  font-size: 18px;
  line-height: 20px;
  padding: 7px 5px 5px 5px;
  position: relative;
  margin: 4px 0;
  overflow: hidden;
  cursor: pointer;
  box-sizing: padding-box;
  & > input {
    display: none;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 4px;
    width: 0;
    background-color: var(--primary);
    transition: width 0.3s;
  }
  &[data-isselected='true'] {
    background-color: #6619f9;
  }
  &[data-show='true'] {
    cursor: auto;
    &::after {
      width: ${(props) => props.result};
    }
  }
`;

export const AnswersWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h5`
  font-size: 20px;
  font-weight: bold;
`;

export const Center = styled.span`
  text-align: center;
  margin: 10px auto;
  display: block;
`;
