import React from 'react';

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        fill="#fff"
        d="M22.411 9.215c0 .91-.751 1.652-1.676 1.652-.924 0-1.676-.741-1.676-1.652 0-.91.752-1.65 1.676-1.65.925 0 1.676.74 1.676 1.65zm1.589 0c0 1.775-1.464 3.234-3.303 3.215l-2.872 2.11-.006.001c-.008 1.349-1.111 2.446-2.485 2.459a2.501 2.501 0 01-2.484-1.991l-8.962-3.713c-.39.252-.854.403-1.354.408C1.136 11.716.014 10.61 0 9.254-.014 7.882 1.113 6.76 2.511 6.76a2.5 2.5 0 012.46 1.983l8.97 3.715a2.5 2.5 0 011.428-.4l2.105-2.903C17.507 7.408 18.954 6 20.736 6 22.538 6 24 7.44 24 9.215zm-20.794 1.8l-1.226-.508a1.377 1.377 0 01-.75-1.813 1.413 1.413 0 011.84-.74l1.211.502a1.945 1.945 0 00-1.77-1.136c-1.096.004-1.95.882-1.94 1.929.01 1.028.86 1.897 1.957 1.893.236-.002.464-.047.678-.128zm14.045 3.493c-.01-1.044-.88-1.893-1.939-1.893-.229.001-.455.04-.686.125l1.229.51a1.377 1.377 0 01.751 1.812 1.414 1.414 0 01-1.841.74l-1.23-.51a1.946 1.946 0 001.794 1.144c1.072-.008 1.932-.87 1.922-1.928zm5.703-5.293c0-1.205-.995-2.186-2.219-2.186-1.223 0-2.219.98-2.219 2.186 0 1.205.996 2.186 2.219 2.186 1.224 0 2.219-.98 2.219-2.186z"
      />
    </svg>
  );
}

export default Icon;
