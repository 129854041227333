import { GameCardsWrapper } from 'components/GameCardsWrapper';
import { SubsectionHeader } from 'components/SubsectionHeader';
import { Card } from 'components/Card';
import { useCachedData } from 'apis';
import { Loading } from 'components/Loading';

const Provider = () => {
  const { data, error, isLoading } = useCachedData({ type: 'recentGames' });

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <div>{error.message}</div>;
  }

  return <GameCardsWrapper games={data} />;
};

export const RecentGames = () => (
  <Card as="section" data-margin>
    <SubsectionHeader>Ostatnie gry</SubsectionHeader>
    <Provider />
  </Card>
);
