import styled from 'styled-components';

export const Wrapper = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;
export const WrapperGame = styled.div`
  max-width: 360px;
  width: 100%;
  height: 200px;
  position: relative;
  margin: 5px;
  transition: box-shadow 0.3s;
  &[data-active='true'] {
    box-shadow: 0 0 0 2px var(--primary);
  }
`;

export const Title = styled.a`
  display: block;
  position: absolute;
  bottom: 0px;
  width: 100%;
  font-size: 25px;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  font-weight: bold;
  color: inherit;
  text-decoration: none;
  z-index: 4;
  :nth-child(1) {
    background-color: red;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px auto;
  position: relative;
  flex-flow: row wrap;
  width: 100%;
`;

export const VS = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #2283f6;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 35px;
  font-weight: 25px;
  z-index: 4;
  clip-path: polygon(0 50%, 30% 100%, 70% 100%, 100% 50%, 70% 0, 30% 0);
`;

export const LikeWrapper = styled.button`
  position: absolute;
  height: 100%;
  top: 0;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-size: 20px;
  &[data-color='blue'] {
    left: 0;
    background-color: rgba(0, 83, 255, 0.9);
  }
  &[data-color='red'] {
    right: 0;
    background-color: rgba(255, 83, 0, 0.9);
  }
  transition: filter 0.3s, width 0.3s;
  :hover {
    filter: brightness(150%);
    width: 100px;
  }
`;

export const Result = styled.div`
  width: ${({ result }) => result}%;
  margin: 2px;
  transition: width 0.5s;
  white-space: nowrap;
  &[data-color='blue'] {
    background-color: rgba(0, 83, 255, 0.9);
  }
  &[data-color='red'] {
    background-color: rgba(255, 83, 0, 0.9);
  }
`;

export const External = styled.a`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  font-size: 30px;
`;
