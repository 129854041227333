import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      height="1em"
      width="1em"
    >
      <path
        d="M4378 3930c-119-15-250-93-284-169-17-38-19-40-78-46-34-4-212-31-396-60-369-60-1016-160-1095-170-27-3-160-24-295-46-395-64-1057-166-1161-179-96-12-96-12-136 14-83 55-251 93-358 80-242-29-382-143-360-292 13-86 119-171 260-207 70-18 240-19 305-1 44 11 46 11 120-32 41-24 100-58 130-75 30-16 124-70 208-119s174-101 200-115c26-15 118-68 206-120 87-51 160-93 162-93s87-49 190-110c102-60 188-110 190-110 4 0 65-35 554-320 107-62 220-127 250-144 104-58 97-51 79-83-86-147 65-316 307-344 178-21 335 18 434 107 51 46 70 87 70 150 0 81-53 149-157 203l-43 21 25 53c13 28 57 126 99 217 85 190 145 321 296 650 59 129 126 278 150 330 23 52 73 160 110 240s79 170 91 201l24 57 85 5c136 8 239 57 314 150 27 34 31 46 31 101 0 53-5 69-30 106-36 51-109 102-186 129-66 22-216 33-311 21zm-188-435c29-19 58-35 66-35s14-2 14-4c0-3-27-62-59-133-32-70-78-171-101-223s-75-165-115-250-85-184-100-220c-16-36-68-150-117-254s-95-205-102-225-57-129-111-242l-97-207-77-5c-42-2-83-7-91-11-9-4-95 40-225 115-303 175-584 337-785 454-96 56-272 157-390 225s-294 169-390 225-244 141-328 190c-84 48-155 92-158 96-2 4 1 19 7 32 8 18 22 27 53 32 228 37 712 111 876 135 113 16 369 56 570 88 201 33 493 79 650 102 157 24 431 67 610 96s330 53 336 53c7 1 35-15 64-34z"
        transform="matrix(.1 0 0 -.1 0 512)"
      />
    </svg>
  );
}

export default Icon;
