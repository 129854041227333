import { Loading } from 'components/Loading';
import { useCachedData } from 'apis';
import { SliderContainer } from 'components/SliderContainer';

export const SlidesMainPage = () => {
  const { data, error, isLoading } = useCachedData({ type: 'slides' });

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <div>{error.message}</div>;
  }

  return <SliderContainer slides={data} />;
};
