import { MainLayout } from 'components/MainLayout';
import { Posts } from 'components/Posts';
import { Card } from 'components/Card';
import { Challange } from 'components/Challange';
import { PollsCard } from 'components/PollsCard';
import { AdsBlock } from 'components/AdsBlock';
import { SlidesMainPage } from './SlidesMainPage';
import { CategoriesCard } from './CategoriesCard';
import { UpcomingPremieresCard } from './UpcomingPremieresCard';
import { RecentGames } from './RecentGames';
import { AsideWrapper, ContentWrapper, Content, Main, Divider } from './MainPage.styles';
import { PopularNews } from "../PopularNews"
import { CalendarEvents } from './CalendarEvents';
import { RecentYoutubeVideos } from './RecentYoutubeVideos';

export const MainPage = ({ children, isSg, isArt, isGame }) => (
  <MainLayout>
    {!children && <SlidesMainPage />}
    {isSg && <AdsBlock id="sg_bill_top" />}
    {isArt && <AdsBlock id="art_bill_top" />}
    {isGame && <AdsBlock id="sekcja_bill_top" />}
    <ContentWrapper>
      <Content>
        <Main>
          <Card data-margin>{children || <Posts redirectToNews />}</Card>
        </Main>
        <Card data-margin>
          <Challange />
        </Card>
      </Content>

      <Divider />

      <AsideWrapper>
        <CategoriesCard />
        {isSg && <AdsBlock id="sg_half_1" />}
        {isArt && <AdsBlock id="art_half_1" />}
        <UpcomingPremieresCard />
        {isSg && <AdsBlock id="sg_half_2" />}
        {isArt && <AdsBlock id="art_half_2" />}
        <CalendarEvents />
        <PollsCard />
      </AsideWrapper>
    </ContentWrapper>
    {isSg && <AdsBlock id="sg_bill_bottom" />}
    {isArt && <AdsBlock id="art_bill_bottom" />}
    {isGame && <AdsBlock id="sekcja_bill_bottom" />}
    <PopularNews />
    <RecentGames />
    <RecentYoutubeVideos />
  </MainLayout>
);
