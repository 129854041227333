import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      height="1em"
      width="1em"
    >
      <path
        d="M2127 4356c-50-18-85-51-108-101-17-37-19-73-19-450 0-376 2-413 19-450 20-44 69-92 104-100 12-3 218-4 457-3l435 3 33 23c18 13 41 41 53 63 19 38 20 56 17 477l-3 439-28 36c-56 74-44 72-507 74-334 2-423 0-453-11zm773-546v-340h-680v680h680v-340zM2490 2855v-225H840v-601l73 3 72 3 3 223 2 222h1500v-450h150v450h1500v-450h150v600H2640v450h-150v-225zM374 1841c-40-25-71-65-85-110-7-24-9-179-7-452l3-415 25-37c13-20 43-46 65-59l40-23h861l43 28c81 54 81 53 81 531 0 406-1 421-21 463-14 29-35 52-63 68l-43 25H839c-407 0-436-1-465-19zm806-536V960H500v690h680v-345zM2095 1842c-40-25-61-50-80-95-13-31-15-99-15-444 0-454 1-458 69-516 55-46 83-48 520-45l409 3 43 30c81 58 79 44 79 528 0 406-1 430-20 467-11 21-34 49-52 62l-33 23-445 3c-420 2-447 1-475-16zm805-537V960h-680v690h680v-345zM3813 1835c-27-16-49-40-62-68-20-42-21-58-21-466 0-403 1-424 20-461 11-22 39-52 62-67l42-28h861l40 23c22 13 52 39 65 59l25 37 3 415c3 443 1 466-44 521-52 61-42 60-516 60h-433l-42-25zm817-530V960h-680v690h680v-345z"
        transform="matrix(.1 0 0 -.1 0 512)"
      />
    </svg>
  );
}

export default Icon;
