import styled from 'styled-components';
import { Image as ImageComponent } from 'components/Image';

export const Wrapper = styled.a`
  position: relative;
  text-decoration: none;
  color: var(--font);
  min-width: 300px;
  max-width: 650px;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  transition: box-shadow 0.3s;
  margin: 5px 10px 10px 10px;
  border-radius: var(--br);
  overflow: hidden;
  &:hover {
    box-shadow: 0 0 0 2px var(--primary);
  }
  &:focus {
    outline: solid 3px white;
  }
`;

export const Image = styled(ImageComponent)`
  padding-top: 56.25%;
  width: 100%;
  height: 100%;
`;

export const Title = styled.h3`
  font-size: 18px;
  line-height: 20px;
  padding-bottom: 10px;
  font-weight: normal;
  height: 40px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const Bottom = styled.div`
  padding: 10px;
  font-size: 15px;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--bg-strong);
`;

export const Split = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Skeleton = styled.div`
  background-color: var(--opacity-strong);
  width: ${({ width }) => (width ? `${width}px` : `100%`)};
  height: ${({ height }) => (height ? `${height}px` : `100%`)};
`;
