import { useState } from 'react';
import { FaRegFlag, FaFlag, FaTrashAlt, FaEdit } from 'react-icons/fa';
import { BsEyeFill, BsEyeSlashFill, BsThreeDotsVertical } from 'react-icons/bs';
import { reportComment, deleteComment, hideComment, useComments, useSession } from 'apis';
import { DeleteModal } from 'components/DeleteModal';
import { useAlert } from 'react-alert';
import { useMutation } from 'react-query';
import { SettingsWrapper, Wrapper, SettingsButton } from './index.style';

const Component = ({
  commentId,
  reported,
  contentId,
  contentType,
  hiddenAt,
  setIsEditing,
  author,
  setOpenSettings,
}) => {
  const { refetch } = useComments({ contentId, contentType });
  const [isReported, setIsReported] = useState(!!reported);
  const [isHidden, setIsHidden] = useState(!!hiddenAt);
  const [isOpenDeleteInfo, setIsOpenDeleteInfo] = useState(false);
  const alert = useAlert();
  const { data: session } = useSession();
  const userId = session?.user?.id;
  const isAuthor = author.id === userId;
  const isAdmin = session?.user?.role === 'admin';

  const reportMutation = useMutation(reportComment, {
    onSuccess: () => {
      if (isReported) {
        alert.success('Zgłoszenie zostało anulowane');
      } else {
        alert.success('Komentarz został zgłoszony');
      }
      setIsReported((v) => !v);
    },
    onError: () => {
      alert.error('Nie udało się zgłosić tego komentarza');
    },
  });

  const deleteMutation = useMutation(deleteComment, {
    onSuccess: () => {
      setIsOpenDeleteInfo(false);
      alert.success('Pomyślnie usunąłeś komentarz');
      refetch();
    },
    onError: () => {
      alert.error('Nie udało się usunąć komentarza');
    },
  });

  const hideMutation = useMutation(hideComment, {
    onSuccess: () => {
      if (isHidden) {
        alert.success('Pomyślnie odkryłeś komentarz');
      } else {
        alert.success('Pomyślnie ukryłeś komentarz');
      }
      setIsHidden((v) => !v);
    },
    onError: () => {
      alert.error('Nie udało się ukryć komentarza');
    },
  });

  /* ****** */

  const handleReport = async () => {
    if (isReported && !isAdmin) return;
    // eslint-disable-next-line no-alert
    const reportConfirm = window.confirm(
      isReported ? 'Czy napewno chcesz anulować swoje zgłoszenie?' : 'Czy napewno chcesz zgłosić ten komentarz?'
    );
    if (reportConfirm) {
      reportMutation.mutate({ commentId, isReported });
    }
  };

  const handleDelete = () => {
    deleteMutation.mutate({ commentId });
  };

  const handleHide = () => {
    hideMutation.mutate({
      commentId,
      isHidden,
    });
  };

  const isHiddenText = isHidden ? 'Odkryj' : 'Ukryj';

  return (
    <Wrapper>
      {(isAuthor || isAdmin) && (
        <SettingsButton
          onClick={() => {
            setIsOpenDeleteInfo(true);
          }}
          aria-label="Usuń komentarz"
          title="Usuń komentarz"
        >
          <FaTrashAlt />
        </SettingsButton>
      )}

      <SettingsButton
        onClick={() => {
          setOpenSettings(false);
          setIsEditing((prev) => !prev);
        }}
        aria-label="Edytuj komentarz"
        title="Edytuj komentarz"
      >
        <FaEdit />
      </SettingsButton>

      {isAdmin && (
        <SettingsButton
          aria-label={`${isHiddenText} komentarz`}
          title={`${isHiddenText} komentarz`}
          data-active={isHidden}
          onClick={handleHide}
        >
          {isHidden ? <BsEyeSlashFill /> : <BsEyeFill />}
        </SettingsButton>
      )}

      <SettingsButton aria-label="Zgłoś komentarz" title="Zgłoś komentarz" onClick={handleReport}>
        {isReported && isAdmin ? <FaFlag /> : <FaRegFlag />}
      </SettingsButton>

      {isOpenDeleteInfo && (
        <DeleteModal
          onDelete={handleDelete}
          onRequestClose={() => setIsOpenDeleteInfo(false)}
          title="Usuń komentarz"
          loading={deleteMutation.isLoading}
        >
          Czy napweno chcesz usunąć ten komentarz ?<br /> Zostaną usunięte wszystkie zagnieżdzone komentarze oraz
          like/dislike do każdego komentarza
        </DeleteModal>
      )}
    </Wrapper>
  );
};

export const SettingArea = (props) => {
  const [openSettings, setOpenSettings] = useState(false);
  const { data: session } = useSession();

  if (!session) {
    return null;
  }

  return (
    <>
      <SettingsWrapper>
        <SettingsButton title="Ustawienia" onClick={() => setOpenSettings((prev) => !prev)} data-background>
          <BsThreeDotsVertical />
        </SettingsButton>
      </SettingsWrapper>
      {openSettings && <Component {...props} setOpenSettings={setOpenSettings} />}
    </>
  );
};
