import { Image } from 'components/Image';
import { PillsGamesWrapper } from 'components/Pills';
import Link from 'next/link';
import { GameIcons } from 'components/GameIcons';
import { ImageWrapper, Card, Title, Main } from './GameCard.styles';

export const GameCard = ({ game }) => {
  const { image, title, games_genres, permalink } = game;

  return (
    <Link href={`/games/${permalink}`} passHref>
      <Card title={title}>
        <ImageWrapper>
          <Image width={200} height={90} src={image} alt={`${title} game details`} layout="responsive" />
        </ImageWrapper>
        <Main>
          <Title>{title}</Title>
          <PillsGamesWrapper games_genres={games_genres} />
        </Main>
        <GameIcons game={game} />
      </Card>
    </Link>
  );
};
