import Link from 'next/link';
import styled from 'styled-components';
import { useGetHref } from './Pagination.hooks';

export const ListItem = styled.a`
  min-width: 40px;
  height: 40px;
  border-radius: 4px;
  border: none;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 5px;
  padding: 0px 6px 0px 6px;
  background-color: var(--bg-strong);
  color: var(--font);
  font-weight: 500;
  font-size: ${({ page }) => (page > 999 ? 15 : 20)}px;
  transition: opacity 0.3s;
  text-decoration: none;
  &:hover {
    opacity: 0.5;
  }
  &:disabled {
    cursor: unset;
    opacity: 0.5;
  }
  &[data-active='true'] {
    background-color: var(--primary);
    color: #4b3400;
  }
`;

export const PaginationItem = ({ disabled, active, page, children }) => {
  const href = useGetHref(page);

  if (disabled) {
    return (
      <ListItem as="button" disabled={disabled}>
        {children}
      </ListItem>
    );
  }
  return (
    <Link href={href} passHref>
      <ListItem data-active={active} disabled={disabled} title={`Page ${children}`}>
        {children}
      </ListItem>
    </Link>
  );
};
