import styled from 'styled-components';
import PropTypes from 'prop-types';
import { PaginationItem } from './PaginationItem';
import { usePagination } from './Pagination.hooks';

export const Wrapper = styled.div`
  margin: 50px auto;
  padding: 20px;
  background-color: var(--bg-light);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row wrap;
`;

export const Pagination = ({ currentPage, maxPage }) => {
  const { pages, next, prev, nextMany, prevMany, first, last } = usePagination({ currentPage, maxPage });

  return (
    <Wrapper aria-label="Pagination">
      <PaginationItem page={prev} disabled={prev === null}>
        &larr;
      </PaginationItem>
      {first && <PaginationItem page={first}>{first}</PaginationItem>}
      {prevMany && <PaginationItem page={prevMany}>...</PaginationItem>}
      {pages.map((page) => (
        <PaginationItem page={page} key={page} active={page === currentPage}>
          {page}
        </PaginationItem>
      ))}
      {nextMany && <PaginationItem page={nextMany}>...</PaginationItem>}
      {last && <PaginationItem page={last}>{last}</PaginationItem>}
      <PaginationItem page={next} disabled={next === null}>
        &rarr;
      </PaginationItem>
    </Wrapper>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  maxPage: PropTypes.number.isRequired,
};
