import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      height="1em"
      width="1em"
    >
      <path
        d="M2547 4800c-115-20-89 4-1163-1072C564 2906 369 2706 351 2666c-33-72-44-146-31-213 24-134-15-90 1024-1129 725-726 975-970 1016-993 52-29 61-31 164-31 101 0 112 2 165 30 46 25 247 221 1058 1033 684 685 1008 1015 1022 1044 52 104 52 99 49 1146l-3 972-32 66c-39 80-111 150-191 187l-57 27-970 1c-533 1-992-2-1018-6zm1931-1280l2-945-967-967c-533-533-975-968-983-968-20 0-1880 1860-1880 1880 0 8 435 450 967 983l968 967 945-2 945-3 3-945z"
        transform="matrix(.1 0 0 -.1 0 512)"
      />
      <path
        d="M3712 4032c-214-77-233-375-30-476 142-71 319 7 364 160 58 201-137 386-334 316zM2315 3509c-114-17-240-76-304-143-19-20-41-36-49-36s-34 18-58 40c-45 41-70 48-105 29-48-25-42-85 15-138 14-14 26-31 26-39 0-7-25-44-56-80-128-154-171-333-124-516 50-196 181-335 380-402 77-26 253-26 330 0 83 28 140 60 202 110 31 25 63 46 70 46 19 0 318-302 318-321s-34-51-80-75c-45-23-151-23-202-1-21 9-55 29-75 45-81 64-162 62-228-3-73-74-67-165 16-245 127-122 333-176 508-135 84 20 174 65 242 120 61 51 78 54 105 24 44-50 132-64 158-27 27 38 20 70-24 113-22 21-40 45-40 53s21 37 47 66c56 62 108 168 128 261 18 88 18 132 0 220-67 318-377 515-687 436-93-24-158-56-227-113-30-24-60-47-67-53-11-7-52 28-174 150-88 88-160 165-160 171 0 15 49 59 87 78 42 21 161 21 211 0 20-8 48-26 62-39 38-36 97-58 139-50 125 22 180 153 110 259-91 137-309 223-494 195zm-58-691c84-84 153-160 153-169 0-26-64-77-112-90-154-41-308 67-308 217 0 75 60 194 98 194 9 0 85-69 169-152zm823-231c28-14 56-39 75-65 25-36 30-55 33-117 2-41-1-89-8-110-13-40-65-105-83-105-15 0-327 311-327 326 0 13 47 57 80 75 62 32 157 31 230-4z"
        transform="matrix(.1 0 0 -.1 0 512)"
      />
    </svg>
  );
}

export default Icon;
