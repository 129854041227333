import Link from 'next/link';
import { Card, CardHeader, CardItem, CardList } from 'components/Card';
import { useCachedData } from 'apis';
import { Loading } from 'components/Loading';

const Provider = () => {
  const { data, error, isLoading } = useCachedData({ type: 'categories' });

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <div>{error.message}</div>;
  }

  return (
    <CardList>
      {data.map(({ id, title, permalink }) => (
        <li key={id}>
          <Link href={`/categories/${permalink}`} passHref>
            <CardItem title={title}>{title}</CardItem>
          </Link>
        </li>
      ))}
    </CardList>
  );
};

export const CategoriesCard = () => (
  <Card as="nav" data-margin>
    <CardHeader>Kategorie</CardHeader>
    <Provider />
  </Card>
);
