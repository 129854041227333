import styled from 'styled-components';

export const SettingsWrapper = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--bg-strong);
  position: absolute;
  top: 0px;
  right: 40px;
`;

export const SettingsButton = styled.button`
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  border: none;
  transition: background-color 0.3s;
  &[data-background='true'] {
    background-color: transparent;
  }
  &:hover,
  &[data-active='true'] {
    background-color: var(--opacity-medium);
  }
`;
